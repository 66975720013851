/* SCSS Document */
/***************
GOOGLE FONT
*****************/
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,700;1,300;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
/**************  
VARIABLES
***************/
h1, h2, h3, h4, h5, h6 {
  font-family: "Cormorant Garamond", serif;
}

body, p, li, a {
  font-family: "Open Sans", sans-serif;
}

body {
  background: url("/background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 600px) {
  body {
    background-position: right bottom;
  }
}

#result-text.invalid {
  color: red;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
}