/* SCSS Document */

/***************
GOOGLE FONT
*****************/
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,700;1,300;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
/**************  
VARIABLES
***************/
$default:#000000;
$accent:#ffc709;
$lightAccent:#fff8b7;
$grey:#C4C4C4;
$lightGrey:#EBEBEB;
$darkGrey:#2E2E2E;
$headFont: 'Cormorant Garamond', serif;
$defaultFont:'Open Sans', sans-serif;

h1,h2,h3,h4,h5,h6 {
    font-family:$headFont;
}
body,p,li,a {
    font-family:$defaultFont;
}
body {
    background:url('/background.jpg');
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    @media screen and (max-width: 600px){
        background-position: right bottom;
    }
}
#result-text {
    &.invalid {
        color:red;
        font-family:$defaultFont;
        font-size:1.4rem;
    }
}
